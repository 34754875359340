import React from 'react'
import Helmet from 'react-helmet'


import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Hero } from '../components/Hero'
import { PageLayout } from '../components/PageLayout'
import config from '../utils/config'

import tsp from '../../content/thumbnails/thread-tsp.jpg'
import pom from '../../content/thumbnails/thread-pom.jpg'
import omy from '../../content/thumbnails/thread_omy.jpg'
import tehm from '../../content/thumbnails/thread_tehm.jpg'
import ikigai from '../../content/thumbnails/thread-ikigai.jpg'
import rdpd from '../../content/thumbnails/bs-rdpd.jpg'
import poh from '../../content/thumbnails/bs-poh.jpg'
import fortitude from '../../content/thumbnails/bs-f.jpg'
import geeta from '../../content/thumbnails/bs-geeta.jpg'
import tls from '../../content/thumbnails/bs-tls.jpg'
import bmu from '../../content/thumbnails/bs-bmu.jpg'
import sapiens from '../../content/thumbnails/bs-sapiens.png'
import deepwork from '../../content/thumbnails/bs-deep-work.jpg'
import tcby from '../../content/thumbnails/bs-tcby.jpg'
import tposm from '../../content/thumbnails/bs-tposm.jpg'
import tseg from '../../content/thumbnails/bs-tseg.png'

const manuals = [
    { label: 'Rich Dad Poor Dad', image: rdpd, url: '/rich-dad-poor-dad'},
    { label: 'Ikigai', image: ikigai, url: '/ikigai'},
    { label: 'The Power of Habit', image: poh, url: '/the-power-of-habit'},
    { label: 'The Success Principles', image: tsp, url: '/the-success-principles'},
    { label: 'Psychology of Money', image: pom, url: '/psychology-of-money'},
    { label: 'On Managing Yourself', image: omy, url: '/on-managing-yourself'},
    { label: 'Sapiens', image: sapiens, url: '/sapiens'},
    { label: 'The Everday Hero Manifesto', image: tehm, url: '/the-everday-hero-manifesto'},
    { label: 'Fortitude', image: fortitude, url: '/fortitude'},
    { label: 'Bhagavad Geeta', image: geeta, url: '/bhagavad-geeta'},
    { label: 'The Lean Startup', image: tls, url: '/the-lean-startup'},
    { label: 'Business Model You', image: bmu, url: '/business-model-you'},
    { label: 'Deep Work', image: deepwork, url: '/deep-work'},
    { label: 'The Complete Book of Yoga', image: tcby, url: '/tcby'},
    { label: 'The Power of Subconcious Mind', image: tposm, url: '/tposm'},
    { label: 'The Software Engineer"s Guidebook', image: tseg, url: '/tseg'}
]

export default function Bookshelf() {
  const title = 'Bookshelf'
  const description =
    'A collection of books that have influenced me in some way or the other.'

  return (
    <>
      <Helmet title={`${title} | ${config.siteTitle}`} />
      <SEO />

      <PageLayout>
        <Hero title={title} description={description} />

        <div className="cards">
          {manuals.map((manual) => {
            return (
              <div
                className="image-thumbnail"
                style={{
                  backgroundImage: `url('${manual.image}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
                alt={manual.label}
              />
            )
          })}
        </div>
      </PageLayout>
    </>
  )
}

Bookshelf.Layout = Layout
